/* work-sans-100 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url('theme/flotteladen/fonts/work-sans-v18-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('theme/flotteladen/fonts/work-sans-v18-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('theme/flotteladen/fonts/work-sans-v18-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('theme/flotteladen/fonts/work-sans-v18-latin-100.woff') format('woff'), /* Modern Browsers */
  url('theme/flotteladen/fonts/work-sans-v18-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('theme/flotteladen/fonts/work-sans-v18-latin-100.svg#WorkSans') format('svg'); /* Legacy iOS */
}