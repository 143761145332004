.print-break-after {
  display: block;
  page-break-after: always !important;
  break-after: page;
  position: relative;
}

@media print {

  .print-break-after {
    display: block;
    page-break-after: always !important;
    break-after: page;
    position: relative;
  }
}